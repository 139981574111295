/**
 * Your installation or use of this SugarCRM file is subject to the applicable
 * terms available at
 * http://support.sugarcrm.com/Resources/Master_Subscription_Agreements/.
 * If you do not agree to all of the applicable terms or do not have the
 * authority to bind the entity as an authorized representative, then do not
 * install or use this SugarCRM file.
 * 
 * Copyright (C) SugarCRM Inc. All rights reserved.
 */

export const ColorBaseBlack = "#000000";
export const ColorBaseWhite = "#ffffff";
export const ColorBaseTransparent = "#000000"; // original
export const ColorBaseGray50 = "#f8fafc";
export const ColorBaseGray100 = "#f1f5f9";
export const ColorBaseGray200 = "#e1e7ef";
export const ColorBaseGray300 = "#cbd5e1";
export const ColorBaseGray400 = "#94a3b8";
export const ColorBaseGray500 = "#65758b";
export const ColorBaseGray600 = "#48566a";
export const ColorBaseGray700 = "#344256";
export const ColorBaseGray800 = "#1d283a";
export const ColorBaseGray900 = "#0f1729";
export const ColorBaseGray950 = "#020617";
export const ColorBaseRed50 = "#fef1f1";
export const ColorBaseRed100 = "#fee1e1";
export const ColorBaseRed200 = "#fec8c8";
export const ColorBaseRed300 = "#fca6a6";
export const ColorBaseRed400 = "#f87272";
export const ColorBaseRed500 = "#ef4343";
export const ColorBaseRed600 = "#dc2828";
export const ColorBaseRed700 = "#ba1c1c";
export const ColorBaseRed800 = "#981b1b";
export const ColorBaseRed900 = "#811d1d";
export const ColorBaseRed950 = "#430a0a";
export const ColorBaseOrange50 = "#fff6eb";
export const ColorBaseOrange100 = "#ffedd6";
export const ColorBaseOrange200 = "#fed6a9";
export const ColorBaseOrange300 = "#fdba72";
export const ColorBaseOrange400 = "#fb923c";
export const ColorBaseOrange500 = "#f97415";
export const ColorBaseOrange600 = "#e9590c";
export const ColorBaseOrange700 = "#c03f0c";
export const ColorBaseOrange800 = "#9b3412";
export const ColorBaseOrange900 = "#7d2d12";
export const ColorBaseOrange950 = "#451507";
export const ColorBaseAmber50 = "#fffbeb";
export const ColorBaseAmber100 = "#fef3c8";
export const ColorBaseAmber200 = "#fde68b";
export const ColorBaseAmber300 = "#fcd44f";
export const ColorBaseAmber400 = "#fbbd23";
export const ColorBaseAmber500 = "#f59f0a";
export const ColorBaseAmber600 = "#db7706";
export const ColorBaseAmber700 = "#b35309";
export const ColorBaseAmber800 = "#90400e";
export const ColorBaseAmber900 = "#76350f";
export const ColorBaseAmber950 = "#451a03";
export const ColorBaseYellow50 = "#fefce7";
export const ColorBaseYellow100 = "#fef9c3";
export const ColorBaseYellow200 = "#fef08b";
export const ColorBaseYellow300 = "#fddf49";
export const ColorBaseYellow400 = "#facc14";
export const ColorBaseYellow500 = "#e7b008";
export const ColorBaseYellow600 = "#c88a04";
export const ColorBaseYellow700 = "#a26107";
export const ColorBaseYellow800 = "#864e0e";
export const ColorBaseYellow900 = "#733f12";
export const ColorBaseYellow950 = "#412006";
export const ColorBaseLime50 = "#f7fee7";
export const ColorBaseLime100 = "#ebfcca";
export const ColorBaseLime200 = "#d9f99f";
export const ColorBaseLime300 = "#bef263";
export const ColorBaseLime400 = "#a1e633";
export const ColorBaseLime500 = "#82cb15";
export const ColorBaseLime600 = "#66a50d";
export const ColorBaseLime700 = "#4c7b0f";
export const ColorBaseLime800 = "#406312";
export const ColorBaseLime900 = "#355214";
export const ColorBaseLime950 = "#1a2e05";
export const ColorBaseGreen50 = "#f2fdf5";
export const ColorBaseGreen100 = "#defce9";
export const ColorBaseGreen200 = "#bbf7d0";
export const ColorBaseGreen300 = "#85efac";
export const ColorBaseGreen400 = "#4ade80";
export const ColorBaseGreen500 = "#21c45d";
export const ColorBaseGreen600 = "#16a249";
export const ColorBaseGreen700 = "#157f3c";
export const ColorBaseGreen800 = "#166434";
export const ColorBaseGreen900 = "#14522d";
export const ColorBaseGreen950 = "#052e16";
export const ColorBaseEmerald50 = "#edfdf5";
export const ColorBaseEmerald100 = "#d1fae5";
export const ColorBaseEmerald200 = "#a5f3cf";
export const ColorBaseEmerald300 = "#6ee7b7";
export const ColorBaseEmerald400 = "#36d399";
export const ColorBaseEmerald500 = "#10b77f";
export const ColorBaseEmerald600 = "#059467";
export const ColorBaseEmerald700 = "#047756";
export const ColorBaseEmerald800 = "#066046";
export const ColorBaseEmerald900 = "#064c39";
export const ColorBaseEmerald950 = "#022c22";
export const ColorBaseTeal50 = "#f2fdfa";
export const ColorBaseTeal100 = "#cbfbf0";
export const ColorBaseTeal200 = "#98f6e3";
export const ColorBaseTeal300 = "#5dead5";
export const ColorBaseTeal400 = "#2bd4bd";
export const ColorBaseTeal500 = "#14b8a5";
export const ColorBaseTeal600 = "#0d968b";
export const ColorBaseTeal700 = "#0f756d";
export const ColorBaseTeal800 = "#115f5a";
export const ColorBaseTeal900 = "#134e4a";
export const ColorBaseTeal950 = "#042f2e";
export const ColorBaseCyan50 = "#ebfeff";
export const ColorBaseCyan100 = "#cdfafe";
export const ColorBaseCyan200 = "#a6f4fc";
export const ColorBaseCyan300 = "#67e8f9";
export const ColorBaseCyan400 = "#20d3ee";
export const ColorBaseCyan500 = "#07b6d5";
export const ColorBaseCyan600 = "#088eaf";
export const ColorBaseCyan700 = "#0e7490";
export const ColorBaseCyan800 = "#155f75";
export const ColorBaseCyan900 = "#164f64";
export const ColorBaseCyan950 = "#083344";
export const ColorBaseBlue50 = "#f0f9ff";
export const ColorBaseBlue100 = "#e1f3fe";
export const ColorBaseBlue200 = "#bae5fd";
export const ColorBaseBlue300 = "#7ed4fc";
export const ColorBaseBlue400 = "#3abff8";
export const ColorBaseBlue500 = "#0da2e7";
export const ColorBaseBlue600 = "#0284c5";
export const ColorBaseBlue700 = "#0369a0";
export const ColorBaseBlue800 = "#075783";
export const ColorBaseBlue900 = "#0c4a6e";
export const ColorBaseBlue950 = "#082f49";
export const ColorBaseCobalt50 = "#f0f6ff";
export const ColorBaseCobalt100 = "#dcebfe";
export const ColorBaseCobalt200 = "#bedbfe";
export const ColorBaseCobalt300 = "#91c3fd";
export const ColorBaseCobalt400 = "#61a6fa";
export const ColorBaseCobalt500 = "#3c83f6";
export const ColorBaseCobalt600 = "#2463eb";
export const ColorBaseCobalt700 = "#1d4fd7";
export const ColorBaseCobalt800 = "#1e3fae";
export const ColorBaseCobalt900 = "#1e3b8a";
export const ColorBaseCobalt950 = "#172554";
export const ColorBaseIndigo50 = "#f0f3ff";
export const ColorBaseIndigo100 = "#e0e8ff";
export const ColorBaseIndigo200 = "#c8d3fe";
export const ColorBaseIndigo300 = "#a6b4fc";
export const ColorBaseIndigo400 = "#828df8";
export const ColorBaseIndigo500 = "#6467f2";
export const ColorBaseIndigo600 = "#5048e5";
export const ColorBaseIndigo700 = "#463acb";
export const ColorBaseIndigo800 = "#372fa2";
export const ColorBaseIndigo900 = "#312e7f";
export const ColorBaseIndigo950 = "#1e1b4b";
export const ColorBaseViolet50 = "#f6f5ff";
export const ColorBaseViolet100 = "#ebe7fe";
export const ColorBaseViolet200 = "#ded7fe";
export const ColorBaseViolet300 = "#c3b4fd";
export const ColorBaseViolet400 = "#a689fa";
export const ColorBaseViolet500 = "#895af6";
export const ColorBaseViolet600 = "#7c3bed";
export const ColorBaseViolet700 = "#6b26d9";
export const ColorBaseViolet800 = "#5a21b5";
export const ColorBaseViolet900 = "#4d1d95";
export const ColorBaseViolet950 = "#2e1065";
export const ColorBasePurple50 = "#faf5ff";
export const ColorBasePurple100 = "#f2e5ff";
export const ColorBasePurple200 = "#ead6ff";
export const ColorBasePurple300 = "#d8b4fe";
export const ColorBasePurple400 = "#bf83fc";
export const ColorBasePurple500 = "#a855f7";
export const ColorBasePurple600 = "#9234ea";
export const ColorBasePurple700 = "#7e22ce";
export const ColorBasePurple800 = "#6a21a6";
export const ColorBasePurple900 = "#591c87";
export const ColorBasePurple950 = "#3c0764";
export const ColorBaseFuschia50 = "#fdf5ff";
export const ColorBaseFuschia100 = "#f9e5ff";
export const ColorBaseFuschia200 = "#f5d2fe";
export const ColorBaseFuschia300 = "#f0abfc";
export const ColorBaseFuschia400 = "#e87bf9";
export const ColorBaseFuschia500 = "#d948ef";
export const ColorBaseFuschia600 = "#bf27d3";
export const ColorBaseFuschia700 = "#a31daf";
export const ColorBaseFuschia800 = "#85198f";
export const ColorBaseFuschia900 = "#000000";
export const ColorBaseFuschia950 = "#4a044e";
export const ColorBasePink50 = "#fdf2f8";
export const ColorBasePink100 = "#fce8f4";
export const ColorBasePink200 = "#fbd0e8";
export const ColorBasePink300 = "#f9a9d5";
export const ColorBasePink400 = "#f471b5";
export const ColorBasePink500 = "#ec4699";
export const ColorBasePink600 = "#db2979";
export const ColorBasePink700 = "#bf185d";
export const ColorBasePink800 = "#9b174c";
export const ColorBasePink900 = "#811842";
export const ColorBasePink950 = "#500724";
export const ColorBaseRose50 = "#fff0f1";
export const ColorBaseRose100 = "#ffe5e7";
export const ColorBaseRose200 = "#fecdd3";
export const ColorBaseRose300 = "#fda5af";
export const ColorBaseRose400 = "#fb6f84";
export const ColorBaseRose500 = "#f43e5c";
export const ColorBaseRose600 = "#e21d48";
export const ColorBaseRose700 = "#bf123d";
export const ColorBaseRose800 = "#a1123a";
export const ColorBaseRose900 = "#861336";
export const ColorBaseRose950 = "#4d0519";
